//import React from 'react'

const Footer = () => {
   return null
    /*  return (
        <footer className="footer">
            <span style={{ fontSize: '0.6rem' }}>
                Seinfra, Governo de Minas Gerais. Desenvolvido por Sandro Veríssimo, © 2019.
            </span>
        </footer>
    ) */
}

export default Footer;