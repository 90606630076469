export const cadVehicleFiles = [
    { title: 'CRLV do Veículo', name: 'crlv' },
    { title: 'Declaração de Responsabilidade de Manutenção', name: 'declaracao' },
    { title: 'Certificado de Vistoria', name: 'vistoria' },
    { title: 'Laudo de Pesagem', name: 'pesagem' },
    { title: 'Nota Fiscal Carroceria', name: 'nfCarroceria' },
    { title: 'Nota Fiscal Chassi', name: 'nfChassi' },
    { title: 'Layout do Veículo', name: 'layout' },
    { title: 'Contrato', name: 'contrato' },
    { title: 'Outros Anexos', name: 'outrosAnexos' },

]