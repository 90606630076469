import { createContext } from 'react'

export const ReactContext = createContext(null)

export const  initContext = {
    user: 'MockUser',
    demandId: null
}



